<template>
  <b-overlay :show="loading" rounded="lg">

    <div>
       <!--modal for delivery completed-->
       <b-modal v-model="modalArrived" title="Delivery Order Completed">
        <b-container>
          <b-row>
            <b-col cols="12">
              <b><u>ALL&nbsp;</u></b> items in this DO accepted by the customer?
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex">
            <b-row>
              <b-col cols="12">
                <b>Customer Information</b>
              </b-col>
              <b-col cols="12">
                <span>Customer Name <span style="color:red">(*)</span></span>
                <b-input v-model="receiverName" label="Customer Name" placeholder="Name" class="mb-2" required></b-input>
              </b-col>
              <b-col cols="12">
                <span>Customer Contact Number </span>
                <b-input v-model="receiverNumber" label="Contact Number" placeholder="Contact Number" class="mb-2" ></b-input>
              
              </b-col>
            </b-row>
          </div>
          <div class="w-100 d-flex justify-content-center align-items-center">
          
            <b-button variant="success" class="ml-1" @click="updateComplete"
              >Accepted</b-button
            >
            <b-button variant="danger" class="ml-1" @click="modalArrived = false"
              >No</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for delivery completed-->

      <!--modal for delivery returned-->
      <b-modal v-model="modalReturn" title="Delivery Order Return">
        <b-container>
          <b-row>
            <b-col cols="12">
              Return item(s) to warehouse?
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="updateReturn"
              >Yes</b-button
            >
            <b-button variant="danger" class="ml-1" @click="modalReturn = false"
              >No</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for delivery returned-->

      <!--modal for Redelivery do-->
      <b-modal v-model="modalRedelivery" title="Re-delivery DO">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to Re-delivery this  DO?
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" cols="12">
              <b-form-datepicker
                @input="(value) => fireFiltering(value)"
                placeholder="Add Re-Delivery Date"
                :min="minDate"
                :date-disabled-fn="dateDisabled"
                v-model="reDelivDate"
                style="float: right;  margin-top: 10px;"
              />
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button
              variant="success"
              class="ml-1"
              @click="updateRedelivery"
              >Confirm</b-button
            >
            <b-button
              variant="danger"
              class="ml-1"
              @click="modalRedelivery = false"
              >Cancel</b-button
            >
          </div>
        </template>
      </b-modal>
      <!--modal for Redelivery do-->


      <b-card no-body>
        <!-- Header conditional-->
        <b-card-header v-if="pageone">
          <!-- 1. Common information this DO-->
          <h4 v-if="goBack === 'shipping'">Shipping</h4>
          <h4 v-if="goBack === 'resend'">Retrigger API</h4>
          <h4
            v-if="
              doData.shippingPoint === '1001' &&
                (goBack === 'do-daikin' ||
                  goBack === 'sc-daikin' ||
                  goBack === 'return-daikin'||
                  goBack === 'manual-return-daikin')
            "
          >
            Daikin Warehouse
          </h4>
          <h4
            v-if="
              doData.shippingPoint === '1003' &&
                (goBack === 'do-sumitomo' ||
                  goBack === 'sc-sumitomo' ||
                  goBack === 'return-sumitomo'||
                  goBack === 'manual-return-sumitomo')
            "
          >
            Sumitomo Warehouse
          </h4>
        </b-card-header>
  
  
        <!-- End of Header conditional-->
        <b-card-body>
          <b-col cols="12">
            <b-row>
              <b-col cols="3">
              </b-col>

            </b-row>
          </b-col>
  
          <!-- Separate one by one page on this document-->
          <b-col cols="12" class="mt-2" v-if="pageone">
            <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto d-flex justify-content-center"
            >
              <img :src="daikinLogo" style="width: 50%; height: auto; margin: 0 0 15px 0"/>
            </b-col>
            <!-- 1. Common information this DO-->
            <b-row v-if="processSuccess">
              <b-col cols="12" class="d-flex flex-column align-items-center justify-content-center">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </b-col>
              <!--this for return delivery needed-->
              <b-col cols="12" class="d-flex flex-column align-items-center justify-content-center">
                DO Process successful, this tab/window can be closed
              </b-col>
            </b-row>
            <b-row v-else-if="canProcess">
              <b-col cols="12" class="d-flex flex-column align-items-center justify-content-center">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </b-col>
              <!--this for return delivery needed-->
              <b-col cols="12" style="margin-bottom: 10px;" class="adjust-on-mobile">
                <b-button
                  @click="modalReturn = true"
                  class="w-100 d-flex justify-content-center"
                  variant="danger"
                  >Delivery Returned</b-button
                >
              </b-col>
              <!--this for warehouse role. temporary check when open this page from shipping table list-->
              <b-col cols="12" style="margin-bottom: 10px;" class="adjust-on-mobile">
                <b-button
                  @click="modalArrived = true"
                  class="w-100 d-flex justify-content-center"
                  variant="success"
                  >Accept Delivery</b-button
                >
              </b-col>
              <!--this for return delivery needed-->
              <b-col cols="12" class="adjust-on-mobile">
                <b-button
                  @click="modalRedelivery = true"
                  class="w-100 d-flex justify-content-center"
                  variant="warning"
                  >Re-delivery</b-button
                >
              </b-col>
  
            </b-row>
            <b-row v-else>
              <b-col cols="12" class="d-flex flex-column align-items-center justify-content-center">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </b-col>
              <!--this for return delivery needed-->
              <b-col cols="12" class="d-flex flex-column align-items-center justify-content-center">
                {{errorMessage}}
              </b-col>
            </b-row>
          </b-col>

        </b-card-body>
      </b-card>
    </div>
  </b-overlay>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import axios from "@/axios";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, email } from "@validations";
  import { downloadByPDFMake, dateFormat } from "@/utils/utils.js";
  import { logo } from "./daikin-img-base64.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: ["id", "from","pin"],
    data() {
      return {
        daikinLogo: require('@/assets/images/drcc/daikin-logo.png'),
        stackedStatus: "md",
        perPage: 2,
        currentPage: 1,
        reDelivDate: '',
        minDate: new Date().toISOString().split('T')[0], // Set minimum date to today
        //variabel for save detail DO
        doData: {},
        loading : false,
        //variabel for save movement data item at scan function
        scanItemTables: [],
        scanFields: [
          { key: "material", label: "Item Model" },
          { key: "desc", label: "Description" },
          { key: "qty", label: "Qty" },
          { key: "scan", label: "Scan" },
          { key: "actions", label: "Action" },
        ],
        returnFields: [
          { key: "material", label: "Item Model" },
          { key: "desc", label: "Description" },
          { key: "return", label: "Return" },
          { key: "actions", label: "Action" },
        ],
        itemFields: [{ key: "barcode", label: "Barcode" }],

        //modal

        modalArrived: false,
        modalReturn: false,
        modalRedelivery: false,

        //initiate active page 1 to 3
        pageone: true,
        pagetwo: false,
        pagethree: false,
  
        showCommon: false, //ini buat bandingin detail kalo dibuka dari selain return delivery
        showReturn: false, //ini buat bandingin detail kalo dibuka dari return delivery
  
        postal: [],
        itemCatalog: [],
  
        selectData: "", //this one variable for save item info after scan function
        selectScan: "", //this one variable for save item info after select item to prepare scanning
  
        goBack: "", //this one variable to mark this page is opened from what table list?
        
        //variable used for qr login do
        canProcess:false, // true if user got in from qr login.
        processSuccess:false, // true if change status process is successful
        errorMessage:"", //message shown when canProcess is false
        receiverName:"",
        receiverNumber:"",

        
      };
    },
    mounted() {
      this.goBack = this.$route.params.from;
      
      if (this.pin){
        this.$store
        .dispatch("delivery/detailDelivery", {
          id: this.id,
        })
        .then((Detail) => {
          // console.log(Detail)
          let tableIndex = 0;

          if(Detail.status === "Delivery in Progress"){
            this.canProcess=true;
          }else{
            errorMessage="DO is already processed, Please close this tab/window"
          }
          //ini tuh buat list scan Item per barcode baik yang scan atau yang retur
          this.scanItemTables = Detail.DO_details.reduce((prev, next) => {
            let expandedQty = [];
            var barcodeDO = next.scannedBarcode.map((x) => {
              return x.itemUsed;
            }); //ini map semuanya list scan barcode
            var returnBarcode = next.returnBarcode.map((x) => {
              return x.item;
            }); // ini map semuanya list returnBarcode
            for (let i = 0; i < next.qty; i++) {
              var tempBarcode, tempUnScan;
  
              //for original scan Barcode
              if (barcodeDO[i] === undefined) {
                tempBarcode = "";
              } else {
                tempBarcode = barcodeDO[i];
              }
  
              //for list return barcode
              if (returnBarcode[i] === undefined) {
                tempUnScan = "";
              } else {
                tempUnScan = returnBarcode[i];
              }
              expandedQty.push({
                ...next,
                scannedBarcode: tempBarcode,
                returnBarcode: tempUnScan,
                qty: 1,
                index: tableIndex,
              });
              tableIndex++;
            }
  
            return [...prev, ...expandedQty];
          }, []);
  
          // ini mapping data delivery detail
          if (
            this.doData.status === "Cancel Delivery" 
          ) {
            this.doData = { ...Detail, status: "Delivery Canceled" };
          } else {
            this.doData = Detail;
          }
  
          // if(this.doData.status === "Scanning Completed"){
          //     let a = {...this.doData, status : "Bayu Ganteng"}
          //     console.log('a',a)
          // }
  
          //console.log("this.doData", this.doData);
        })
        .catch((err) => {
          console.log({ err });
        });
      }else{
        this.errorMessage="Data Invalid, Please Re-scan QR Code"
      }
      
  
      
    },
    methods: {
      ...mapActions({
        testScanDO: "delivery/updateDO",
      }),
      isReturn(status){
        let wantedStatus = [
          "Returned",
          "Waiting Acknowledgment",
          "Waiting Approval",
          "Approved",
          "Return Completed",
        ];
        var isReturn = wantedStatus.includes(status)
        console.info('is return ',isReturn)
        return isReturn
      },
      dates(date) {
        return dateFormat(date);
      },
      //ini tuh ketentuan waktu pengiriman delivery
      
      listAllScanned(material) {
        var scanned = this.scanItemTables.filter((x) => {
          return x.material === material && x.scannedBarcode !== "";
        });
        var returned = this.scanItemTables.filter((x) => {
          return x.material === material && x.returnBarcode !== "";
        });
        return {
          scanned: scanned,
          returned: returned,
        };
      },
      showTime(priority) {
        var time;
        if (priority === "11") {
          time = "Morning: 9AM - 12PM";
        } else if (priority === "12") {
          time = "Afternoon: 1PM - 3PM";
        } else if (priority === "13") {
          time = "Afternoon: 3PM - 6PM";
        } else if (priority === "03") {
          time = "Self Collect AMK";
        } else if (priority === "05") {
          time = "Self Collect Sumitomo";
        } else {
          time = "";
        }
        return time;
      },
      //ini waktu klik button "Select" yang ada di halaman kedua
      selectItem(row, type) {
        this.selectScan = row.item;
        this.pagetwo = false;
        this.pagethree = true;
        //console.log("selectScan", this.selectScan);
        //ngebedain data item list delivery seharusnya
        if (type === "Common") {
          this.showCommon = true;
        }
        //ini ngebedain data item list kalau ada permintaan retur
        if (type === "Return") {
          this.showReturn = true;
        }
      },
      getSummary(material) {
        //ini buat cari jumlah discan dan belum discan berdasarkan material id yg dicari
        let itemSummary = this.summary[material];
        if(itemSummary==undefined){
        console.info('item summary is undefined ',material);
        itemSummary={scanQty:0}
      }
        return itemSummary;
      },
      nextPage() {
        //dari page 1 ke page 2
        this.pageone = false;
        this.pagetwo = true;
      },
      backPage() {
        //dari page 2 ke page 1
        this.pageone = true;
        this.pagetwo = false;
      },
      backPageTwo() {
        //dari page 3 ke page 2
        this.pagetwo = true;
        this.pagethree = false;
        //clear both show table detail item list
        this.showCommon = false;
        this.showReturn = false;
      },
      //ini buat cek informasi detail material yg ada di DO_details ke item katalog
      getItem(data) {
        var searchItem = this.itemCatalog.find((x) => {
          return x._id == data;
        });
        var result;
        if (searchItem === undefined) {
          result = {
            itemModel: " ",
          };
        } else {
          result = searchItem;
        }
        //console.log("getItem(data)", result);
        return result;
      },

      // for delivery completed
      updateComplete() {
        //update status to fullfilled : This is for the shipping person if want to finish completed delivery
        const pin = this.pin;
        if(this.receiverName==""||this.receiverName==null||!this.receiverName){
          this.$bvToast.toast("Please insert Customer Name", {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                });
          return;
        }
        var customerCollect = {
          idNumber:this.receiverNumber,
          name:this.receiverName,
          contact:this.receiverNumber
        }

        
        //var data = { ...this.doData };
        //console.log('data', data);
        //data.status = "Fulfilled";
        //data.pin = pin
        //data.customerCollect= customerCollect
        
        this.loading =true
        this.testScanDO({ idDelivery: this.doData._id, deliveryData: {status:"Fulfilled",pin:pin,customerCollect:customerCollect} })
          .then(() => {
            //update item warranty status at inventory
             this.$bvToast.toast("The items has been delivered successfully", {
                  title: "Success",
                  variant: "success",
                  solid: true,
                });
                this.processSuccess=true;
          })
          .catch((e) => {
            // console.log(e)
            this.doData.status = "Delivery in Progress";
            
            if (e.response.data !== undefined) {
              this.$bvToast.toast(e.response.data.message, {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            }else{
              this.$bvToast.toast("Connection error. Please refresh the page and try again", {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            }
          })
          .finally(() => {
                this.loading = false;
                //this.$router.push({ name: 'login-qr' });
            });
        this.modalArrived = false;
      },

      //Method for delivery returned
      updateReturn() {
        //update status to return
        var data = { ...this.doData };
        var previousStatus= data.status;
        const pin = this.pin;
        var goBackPath = (previousStatus=="Delivery in Progress")?"Delivery in Progress":"Scanning Completed"
        data.status = "Returned";
        data.pin = pin;
        
        this.loading = true;
        this.testScanDO({ idDelivery: data._id, deliveryData: data })
          .then(() => {
            this.processSuccess=true;
            this.doData.status = "Returned";

            this.$bvToast.toast(
              "Delivery item(s) has been returned by the customer. Please bring back the item(s) to the warehouse and process the Return DO",
              {
                title: "Success",
                variant: "success",
                solid: true,
              }
            );

            
          })
          .catch((e) => {
            console.log({ e });
            this.doData.status = goBackPath;
            if (e.response.data !== undefined) {
              this.$bvToast.toast(e.response.data.message, {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            }else{
              this.$bvToast.toast("Connection error. Please refresh the page and try again", {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            }
          })
          .finally(() => {
              this.loading = false;
              //this.$router.push({ name: 'login-qr' });
            });

          
        this.modalReturn=false;
          
      },

      //Method for re-delivery

      updateRedelivery() {
        //update status to acknowledge
        let sapDo = this.doData.sapDo;
        this.loading = true;
        this.testScanDO({
          idDelivery: this.doData._id,
          deliveryData: { status: "Re-Delivery", sapDo: sapDo, pin: this.pin, redeliveryDate: this.reDelivDate},
        })
          .then(() => {

            this.$bvToast.toast("Change DO to Re-Delivery Succesfull", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.processSuccess=true;
          })
          .catch((e) => {
            // console.log(e)
            this.doData.status = "Delivery in Progress";
            if (e.response.data !== undefined) {
            this.$bvToast.toast(e.response.data.message, {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            }else{
              this.$bvToast.toast("Connection error. Please refresh the page and try again", {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            }
            
          })
          .finally(() => {
            this.loading = false;
            //this.$router.push({ name: 'login-qr' });
          });
        
        this.modalRedelivery=false;
      },
      //method for add redelivery date to this.reDelivDate
      fireFiltering(value) {
        this.reDelivDate = value;
      },
      dateDisabled(ymd, date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        // disable days that fall on the 13th of the month
        const weekday = date.getDay()
        const day = date.getDate()
        // Return `true` if the date should be disabled
        return weekday === 0
      },
    },
    computed: {
      summary() {
        //ini tuh buat itung ada berapa yang udah di scan atau belum
        return this.scanItemTables.reduce((prev, next) => {
          if (prev[next.material] === undefined) {
            prev[next.material] = { scanQty: 0, emptyQty: 0 };
          }
  
          if (next.scannedBarcode === "") {
            prev[next.material].emptyQty += 1;
          } else {
            prev[next.material].scanQty += 1;
          }
          return prev;
        }, {});
      },
      //mapping list scanned  berdasarkan material
  
      //mapping list detail scanned barcode yg butuh ditampilkan di page 3
      listItemScan() {
        var result = this.scanItemTables.filter((x) => {
          return (
            x.material === this.selectScan.material && x.scannedBarcode !== ""
          );
        });
        return result;
      },
      //mapping list detail permintaan retur barcode yg butuh ditampilkan di page 3
      listItemReturn() {
        var result = this.scanItemTables.filter((x) => {
          return (
            x.material === this.selectScan.material && x.returnBarcode !== ""
          );
        });
        return result;
      },
    },
  };
  </script>
  
  <style>
  /* For mobile phones: */
  @media (max-width: 761px) {
    .adjust-on-mobile {
      margin-bottom: 10px !important;
    }
    .paging-center-mobile {
      justify-content: center !important;
    }
  }
  </style>
  